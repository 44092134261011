<template>
    <div class="global-content">
        <div v-if="loading">
            <van-loading size="32px" vertical>设备信息加载中...</van-loading>
        </div>
        <div v-else>
            <div v-if="visitor=='1'">
                <van-empty image="error" description="当前游客模式，暂无权限查看信息" />
            </div>
            <div v-else>
                <div v-if="!emptyInfo">
                    <div class="block global-margin-bottom global-shadow" v-if="dev.devsta720c=='1'">
                        <div><i class="fa fa-info-circle global-title-fa-big"></i><span class="global-title">状态数据</span></div>
                        <van-divider style="margin-bottom:0 "/>
                        <van-cell title="设备编号" :value="devStat.devCode" />
                        <van-cell title="网关编号" :value="devStat.gateWayCode" />
                        <van-cell title="电池电压" :value="devStat.batVol" />
                        <van-cell title="运行时间" :value="devStat.runTime" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{devStat.uploadTime || '2020-01-01 00:00:00'}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorEnv=='1'">
                        <div><i class="fa fa-cloud global-title-fa"></i><span class="global-title">气象数据</span></div>
                        <van-divider />
                        <div class="data">
                            <van-row>
                                <van-col span="12">温度：<span>{{env.envTemp || 0}} ℃</span></van-col>
                                <van-col span="12">湿度：<span>{{env.envHumi || 0}}%</span></van-col>
                            </van-row>
                            <van-row>
                                <van-col span="12">光照强度：<span>{{env.envIllu || 0}} lux</span></van-col>
                                <van-col span="12">能见度：<span>{{env.envVisi || 0}} m</span></van-col>
                            </van-row>
                            <van-row>
                                <van-col span="12">PM10：<span>{{env.envPm10 || 0}} ug/m³</span></van-col>
                                <van-col span="12">PM2.5：<span>{{env.envPm2p5 || 0}} ug/m³</span></van-col>
                            </van-row>
                            <van-divider style="margin-top:2%"/>
                            <van-row>
                                <van-col span="24" class="update-time">更新时间：{{env.time}}</van-col>
                            </van-row>
                        </div>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorRoadStatus=='1'">
                        <div><i class="fa fa-cloud global-title-fa"></i><span class="global-title">路面状态</span></div>
                        <van-divider />
                        <div class="data">
                            <van-row>
                                <van-col span="12">空气温度：<span>{{roadStatus.airTemperature || '0℃'}}</span></van-col>
                                <van-col span="12">空气湿度：<span>{{roadStatus.airHumidness || '0%'}}</span></van-col>
                            </van-row>
                            <van-row>
                                <van-col span="12">光照强度：<span>{{roadStatus.photometricQuantity || '0ux'}}</span></van-col>
                                <van-col span="12">能见度：<span>{{roadStatus.visibility || '0m'}}</span></van-col>
                            </van-row>
                            <van-row>
                                <van-col span="12">路面温度：<span>{{roadStatus.roadTemperature || '0℃'}}</span></van-col>
                                <van-col span="12">大气压：<span>{{roadStatus.atmosphericPressure || '0pa'}}</span></van-col>
                            </van-row>
                            <van-row>
                                <van-col span="12">PM10：<span>{{roadStatus.pm10 || '0ug/m³'}}</span></van-col>
                                <van-col span="12">PM2.5：<span>{{roadStatus.pm2p5 || '0ug/m³'}}</span></van-col>
                            </van-row>
                            <van-row>
                                <van-col span="12">积水结冰类型：<span>{{roadStatus.waterFreezesType=='0' ? '无':roadStatus.waterFreezesThickness=='1'?'积水':'结冰'}}</span></van-col>
                                <van-col span="12">积水结冰厚度：<span>{{roadStatus.waterFreezesThickness || '0.00m'}}</span></van-col>
                            </van-row>
                            
                            <van-divider style="margin-top:2%"/>
                            <van-row>
                                <van-col span="24" class="update-time">更新时间：{{roadStatus.uploadTime}}</van-col>
                            </van-row>
                        </div>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorCarStat=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">过车统计</span></div>
                        <van-divider style="margin-bottom:0 "/> 
                        <van-cell title="过车数量" :value="car.count+' 辆'" />
                        <van-cell title="平均车速" :value="car.speed+' Km/h'" />
                        <van-cell title="统计周期" :value="car.period || 0 +' s'" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{car.time}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorCar=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">单车数据</span></div>
                        <van-divider style="margin-bottom:0 "/> 
                        <van-cell title="方向" :value="carAlone.carDir=='0'?'不区分':carAlone.carDir=='1'?'来向':'去向'" />
                        <van-cell title="车道" :value="carAlone.carLane" />
                        <van-cell title="车长" :value="carAlone.carLength" />
                        <van-cell title="车速" :value="carAlone.carSpeed+' Km/h'" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{carAlone.uploadTime}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorCarPeriodData=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">车流周期统计</span></div>
                        <van-divider style="margin-bottom:0 "/> 
                        <div class="table">
                            <van-row class="table-head">
                                <van-col span="4">车道</van-col>
                                <van-col span="5">车头时距</van-col>
                                <van-col span="5">平均速度</van-col>
                                <van-col span="5">流量</van-col>
                                <van-col span="5">占有率</van-col>
                            </van-row>
                            <div v-for="(item,index) in carPeriod.laneData" :key="index">
                                <van-row class="table-body">
                                    <van-col span="4">{{index+1}}</van-col>
                                    <van-col span="5">{{item.laneAverageHeadway || 0}}</van-col>
                                    <van-col span="5">{{item.laneAverageSpeed || 0}} km/h</van-col>
                                    <van-col span="5">{{item.laneFlow || 0}}</van-col>
                                    <van-col span="5">{{item.laneOccupancy || 0}} %</van-col>
                                </van-row>
                            </div>
                        </div>
                        <van-cell title="车道数" :value="carPeriod.laneSum" />
                        <van-cell title="周期" :value="carPeriod.period+' s'" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{carPeriod.uploadTime}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorCarFlowType=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">分车型流量</span></div>
                        <van-divider style="margin-bottom:0 "/> 
                        <div class="table">
                            <van-row class="table-head">
                                <van-col span="4">车道</van-col>
                                <van-col span="5">小型车</van-col>
                                <van-col span="5">中型车</van-col>
                                <van-col span="5">大型车</van-col>
                                <van-col span="5">超大型车</van-col>
                            </van-row>
                            <div v-for="(item,index) in carType.flowData" :key="index">
                                <van-row class="table-body">
                                    <van-col span="4">{{index+1}}</van-col>
                                    <van-col span="5">{{item.flowSmall || 0}}</van-col>
                                    <van-col span="5">{{item.flowMedium || 0}}</van-col>
                                    <van-col span="5">{{item.flowLarge || 0}}</van-col>
                                    <van-col span="5">{{item.flowSuperLarge || 0}}</van-col>
                                </van-row>
                            </div>
                        </div>
                        <van-cell title="车道数" :value="carType.laneSum" />
                        <van-cell title="周期" :value="carType.period +' s'" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{carType.uploadTime}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorTruckFlowLane=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">分车道货车流量</span></div>
                        <van-divider style="margin-bottom:0 "/>
                        <van-cell v-for="(item,index) in truck.laneData" :key="index" :title="index==0?'车道一':index==1?'车道二':index==2?'车道三':'车道四'" :value="item" />
                        <van-cell title="车道数" :value="truck.laneSum" />
                        <van-cell title="周期" :value="truck.period +' s'" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{truck.uploadTime}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorBusFlowLane=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">分车道客车流量</span></div>
                        <van-divider style="margin-bottom:0 "/> 
                        <van-cell v-for="(item,index) in bus.laneData" :key="index" :title="index==0?'车道一':index==1?'车道二':index==2?'车道三':'车道四'" :value="item" />
                        <van-cell title="车道数" :value="bus.laneSum" />
                        <van-cell title="周期" :value="bus.period +' s'" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{bus.uploadTime}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorWaterFreezeDevsta=='1'">
                        <div><i class="fa fa-car global-title-fa"></i><span class="global-title">路面设备状态</span></div>
                        <van-divider style="margin-bottom:0 "/> 
                        <van-cell title="设备编号 " :value="roadDev.devCode" />
                        <van-cell title="信号强度" :value="roadDev.rssi" />
                        <van-cell title="运行时间" :value="roadDev.runTime" />
                        <van-cell title="软件版本" :value="roadDev.swVer" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{roadDev.devTime}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorDevsta=='1'">
                        <div><i class="fa fa-info-circle global-title-fa-big"></i><span class="global-title">设备状态A</span></div>
                        <van-divider style="margin-bottom:0 "/>
                        <van-cell title="设备编号" :value="statusA.devCode" />
                        <van-cell title="供电状态" :value="statusA.devPowerType=='1'?'市电':'太阳能'" />
                        <van-cell title="充电状态" :value="statusA.devChargeState=='0'?'未充电':'充电中'" />
                        <van-cell title="信号强度" :value="statusA.rssi" />
                        <van-cell title="运行时长" :value="statusA.runTime" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{statusA.devTime|| '2020-01-01 00:00:00'}}</van-col>
                        </van-row>
                    </div>
                    <div class="block global-margin-bottom global-shadow" v-if="dev.monitorAppBeat=='1'">
                        <div><i class="fa fa-info-circle global-title-fa-big"></i><span class="global-title">设备状态B</span></div>
                        <van-divider style="margin-bottom:0 "/>
                        <van-cell title="设备编号" :value="statusB.devCode" />
                        <van-cell title="网络状态" :value="statusB.netState" />
                        <van-cell title="开机时间" :value="statusB.bootTime" />
                        <van-cell title="运行时长" :value="statusB.runTime" />
                        <van-row>
                            <van-col span="24" class="update-time padding-time">更新时间：{{statusB.uploadTime || '2020-01-01 00:00:00'}}</van-col>
                        </van-row>
                    </div>
                    
                    <div v-if="devControl">
                        <van-button type="info" class="global-btn" @click="controlDev">设备控制</van-button>
                    </div>
                </div>
                <div v-else>
                    <van-empty description="数据为空，请检查配置信息" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Detail',
    data(){
        return{
            devCode:'',
            devType:'',
            loading:true,
            // 过车统计
            car:{
                count:'0',
                speed:'0',
                period:'0',
                time:'2020-01-01 00:00:00'
            },
            // 气象数据
            env:{
                envTemp:'',
                envHumi:'',
                envIllu:'',
                envVisi:'',
                envPm2p5:'',
                envPm10:'',
                time:'2020-01-01 00:00:00'
            },
            //路面状态
            roadStatus:{
                airHumidness:'',
                airTemperature:'',
                photometricQuantity:'',
                atmosphericPressure:'',
                visibility:'',
                pm10:'',
                pm2p5:'',
                waterFreezesThickness:'',
                waterFreezesType:'',
                roadTemperature:''
            },
            // 车流周期
            carPeriod:{
                laneData:[],
                period:'',
                laneSum:'',
                uploadTime:''
            },
            // 分车型流量
            carType:{
                flowData:[],
                laneSum:'',
                period:'',
                uploadTime:''
            },
            // 分车道货车流量
            truck:{
                laneData:[],
                laneSum:'',
                period:'',
                uploadTime:''
            },
            // 分车道客车流量
            bus:{
                laneData:[],
                laneSum:'',
                period:'',
                uploadTime:''
            },
            // 单车数据
            carAlone:{
                carDir:'',
                carLane:'',
                carLength:'',
                carSpeed:'',
                uploadTime:''
            },
            // 路面设备状态
            roadDev:{
                devCode:'',
                rssi:'',
                runTime:'',
                swVer:'',
                workTemp:'',
                devTime:''
            },
            // 设备状态A
            statusA:{
                devCode:'',
                devPowerType:'',
                devChargeState:'',
                rssi:'',
                runTime:'',
                devTime:''
            },
            // 设备状态B
            statusB:{
                devCode:'',
                netState:'',
                bootTime:'',
                runTime:'',
                uploadTime:'',
            },
            // 720C-设备状态
            devStat:{
                devCode:'',
                netState:'',
                bootTime:'',
                runTime:'',
                uploadTime:'',
            },
            // 设备控制部分
            control:{
                controlDevShow:'0',//节目单控制
                controlDevLight:'0',//主动照明控制
                controlDevMediaPeriod:'0',//拍照控制
                controlRtcAudio:'0',//语音对讲控制
                controlMultiMedia:'0' //直播控制
            },
            // 设备展示部分
            dev:{
                monitorDevsta:'0',// 设备状态A
                monitorAppBeat:'0',//设备状态B
                monitorCarStat:'0', //过车统计
                monitorEnv:'0', //气象统计
                monitorCarPeriodData:'0', //实时车流周期统计数据
                monitorCarFlowType:'0', //实时分车型流量数据
                monitorTruckFlowLane:'0', //实时分车道货车流量数据
                monitorBusFlowLane:'0', //实时分车道客车流量数据
                monitorCar:'0', //车辆监控
                monitorRoadStatus:'0', //路面状态监控
                monitorWaterFreezeDevsta:'0', //积水结冰设备监控
                devsta720c:'0', //积水结冰设备监控
            },
            visitor:'1'
        }
    },
    created(){
        this.visitor = window.localStorage.getItem('visitor');
        window.document.title = '设备'+this.$route.query.devCode;
        this.devCode = this.$route.query.devCode;
        this.devType = this.$route.query.devType;
        
        if (this.devType === 'EV4101') {
            this.dev.devsta720c='1';
          //  this.control.controlDevShow='1';
            window.sessionStorage.setItem('control',JSON.stringify(this.control))
            this.get720CStat();
        } else {
            this.getItgsDevAuth();
        }
    },
    computed:{
        // 动态显示与隐藏控制按钮
        devControl(){
            let visitor = window.localStorage.getItem('visitor');
            return visitor !== '1' && (this.$route.query.status=='1' || this.$route.query.status=='online') && Object.values(this.control).some(e => e == '1');
        },
        // 控制显示隐藏提醒标志
        emptyInfo(){
            return this.dev.monitorDevsta == '0' && this.dev.monitorAppBeat == '0' && this.dev.monitorCarStat == '0' && this.dev.monitorEnv == '0' && this.dev.monitorCarPeriodData == '0' && this.dev.monitorCarFlowType == '0' &&this.dev.monitorTruckFlowLane == '0' &&this.dev.monitorBusFlowLane == '0' && this.dev.monitorCar == '0' && this.dev.monitorRoadStatus == '0' && this.dev.monitorWaterFreezeDevsta =='0' && this.dev.devsta720c == '0'
        }
    },
    methods:{
        // 设备控制跳转
        controlDev(){
            this.$router.push({path:'/itgs/controlDev',query: {devCode:this.$route.query.devCode,devType:this.$route.query.devType}})
        },
        getItgsDevAuth(){// 获取设备权限
            this.getdevAuth().then((data) =>{
            if(data){
                // 设备控制部分
                data.controlDevShow ? this.control.controlDevShow = data.controlDevShow :void 0;
                data.controlDevLight ? this.control.controlDevLight = data.controlDevLight :void 0;
                data.controlDevMediaPeriod ? this.control.controlDevMediaPeriod = data.controlDevMediaPeriod :void 0;
                data.controlRtcAudio ? this.control.controlRtcAudio = data.controlRtcAudio :void 0;
                data.controlMultiMedia ? this.control.controlMultiMedia = data.controlMultiMedia :void 0;
                // 设备展示部分
                data.monitorDevsta ? this.dev.monitorDevsta = data.monitorDevsta :void 0;
                data.monitorAppBeat ? this.dev.monitorAppBeat = data.monitorAppBeat :void 0;
                // data.monitorCarStat ? this.dev.monitorCarStat = data.monitorCarStat :void 0;
                data.monitorEnv ? this.dev.monitorEnv = data.monitorEnv :void 0;
                // data.monitorCarFlowType ? this.dev.monitorCarFlowType = data.monitorCarFlowType :void 0;
                // data.monitorTruckFlowLane ? this.dev.monitorTruckFlowLane = data.monitorTruckFlowLane :void 0;
                // data.monitorBusFlowLane ? this.dev.monitorBusFlowLane = data.monitorBusFlowLane :void 0;
                // data.monitorCarPeriodData ? this.dev.monitorCarPeriodData = data.monitorCarPeriodData :void 0;
                data.monitorCar ? this.dev.monitorCar = data.monitorCar :void 0;
                // data.monitorRoadStatus ? this.dev.monitorRoadStatus = data.monitorRoadStatus :void 0;
                // data.monitorWaterFreezeDevsta ? this.dev.monitorWaterFreezeDevsta = data.monitorBusFlowLane :void 0;
                }
                window.sessionStorage.setItem('control',JSON.stringify(this.control))
            }).then((res)=>{
                this.dev.monitorEnv ? this.getdevEnv() : void 0;
            // this.dev.monitorCarStat ? this.getdevCarStat() : void 0;
                this.dev.monitorCar ? this.getdevCarInfo() : void 0;
            // this.dev.monitorCarPeriodData ? this.getdevCarPeriod() : void 0; 
            // this.dev.monitorCarFlowType ? this.getdevCarFlowType() : void 0;
            // this.dev.monitorTruckFlowLane ? this.getdevTruckFlowLane() : void 0;
            // this.dev.monitorBusFlowLane ? this.getdevBusFlowLane() : void 0;
            // this.dev.monitorRoadStatus ? this.getdevRoadStatus() : void 0;
            // this.dev.monitorWaterFreezeDevsta ? this.getdevWaterFreezeDevsta() : void 0;
                this.dev.monitorDevsta ? this.getdevDevsta() : void 0;
                this.dev.monitorAppBeat ? this.getdevAppBeat() : void 0;
                setTimeout(()=>{
                this.loading = false; // 异步操作，等待前方获取数据执行完，优化用户体验
            },1000)
            })
        },
        // 获取设备权限
        getdevAuth(){
            return new Promise((reslove,reject)=>{
                this.$api.ITGS.getAuth({devCode:this.devCode}).then((data)=>{
                    
                    reslove(data);
                })
            })
        },
        get720CStat() {
            
            return new Promise((reslove,reject)=>{
                if(this.dev.devsta720c=='1'){
                    this.$api.ITGS.get720CStat(this.devCode).then(res => {
                        console.log(res);
                            this.devStat.devCode = res.devCode;
                            this.devStat.gateWayCode = res.gateWayCode;
                            this.devStat.batVol = res.batVol;
                            this.devStat.runTime = res.runTime;
                            this.devStat.uploadTime = res.uploadTime;
                        this.loading = false;
                    }).catch(err => {
                        console.error('获取720C设备状态失败:', err);
                        this.loading = false;
                    });
                }


            })
            },
        // 获取天气信息
        getdevEnv(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorEnv=='1'){
                    this.$api.ITGS.getWeather(this.devCode).then((data)=>{
                        if(data){
                            this.env.envTemp = data.envTemp;
                            this.env.envHumi = data.envHumi;
                            this.env.envIllu = data.envIllu;
                            this.env.envVisi = data.envVisi;
                            this.env.envPm2p5 = data.envPm2p5;
                            this.env.envPm10 = data.envPm10;
                            this.env.time = data.uploadTime;
                        }else{
                            this.dev.monitorEnv='0';
                        }
                    })
                }
            })
        },
        // 获取车辆信息
        getdevCarStat(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorCarStat=='1'){
                    this.$api.ITGS.getCarStat(this.devCode).then((data)=>{
                        if(data){
                            this.car.count = data.carCount;
                            this.car.speed = data.carSpeed;
                            this.car.period = data.period;
                            this.car.time = data.devTime;
                        }else{
                            this.dev.monitorCarStat='0'
                        }
                    })
                }
            })
        },
        // 单车数据
        getdevCarInfo(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorCar=='1'){
                    this.$api.ITGS.carInfo(this.devCode).then((data)=>{
                        if(data){
                            this.carAlone.carDir = data.carDir;
                            this.carAlone.carLane = data.carLane;
                            this.carAlone.carLength = data.carLength;
                            this.carAlone.carSpeed = data.carSpeed;
                            this.carAlone.uploadTime = data.uploadTime;
                        }else{
                            this.dev.monitorCar = '0' ; //关闭显示
                        }
                    })
                }
            })
        },
        // 封装车道字符串转数组
        strGoArr(data){
            let arr= [];
            for(let i=0;i<data.length;i++){
                let value = Object.values(data)[i];
                let key = Object.keys(value)[0];
                var val = Object.values(value)[0].split(',');
                if(arr.length==0){
                    for(var j=0; j<val.length;j++){
                        let obj = {};
                        obj[key] = val[j];
                        arr.push(obj);
                    }
                }else{
                    arr.forEach((e,j) => {
                        e[key] = val[j]
                    });
                }
            }
            return arr;
        },
        // 车辆周期数据
        getdevCarPeriod(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorCarPeriodData=='1'){
                    this.$api.ITGS.carPeriod(this.devCode).then((data)=>{
                        if(data){
                            let arr = [
                                {laneAverageHeadway:data.laneAverageHeadway},
                                {laneAverageSpeed:data.laneAverageSpeed},
                                {laneFlow:data.laneFlow},
                                {laneOccupancy:data.laneOccupancy}
                            ];
                            this.carPeriod.laneData = this.strGoArr(arr);
                            this.carPeriod.laneSum = data.laneSum;
                            this.carPeriod.period = data.period;
                            this.carPeriod.uploadTime = data.uploadTime;
                        }else{
                            this.dev.monitorCarPeriodData = '0';
                        }
                    })
                }
            })
        },
        // 分车型流量数据
        getdevCarFlowType(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorCarFlowType=='1'){
                    this.$api.ITGS.carFlowType(this.devCode).then((data)=>{
                        if(data){
                            let arr = [
                                {flowSmall:data.flowSmall},
                                {flowMedium:data.flowMedium},
                                {flowLarge:data.flowLarge},
                                {flowSuperLarge:data.flowSuperLarge}
                            ];
                            this.carType.flowData = this.strGoArr(arr);
                            this.carType.laneSum = data.laneSum;
                            this.carType.period = data.period;
                            this.carType.uploadTime = data.uploadTime;
                        }else{
                            this.dev.monitorCarFlowType='0';
                        }
                    })
                }
            })
        },
        // 分车道货车流量数据
        getdevTruckFlowLane(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorTruckFlowLane=='1'){
                    this.$api.ITGS.truckFlowLane(this.devCode).then((data)=>{
                        if(data){
                            this.truck.laneData=data.truckFlow.split(',');
                            this.truck.laneSum=data.laneSum;
                            this.truck.period=data.period;
                            this.truck.uploadTime=data.uploadTime;
                        }else{
                            this.dev.monitorTruckFlowLane = '0'
                        }
                    })
                }
            })
        },
        // 分车道客车流量数据
        getdevBusFlowLane(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorBusFlowLane=='1'){
                    this.$api.ITGS.busFlowLane(this.devCode).then((data)=>{
                        if(data){
                            this.bus.laneData=data.busFlow.split(',');
                            this.bus.laneSum=data.laneSum;
                            this.bus.period=data.period;
                            this.bus.uploadTime=data.uploadTime;
                        }else{
                            this.dev.monitorBusFlowLane = '0'
                        }
                    })
                }
            })
        },
        // 路面状态数据
        getdevRoadStatus(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorRoadStatus=='1'){
                    this.$api.ITGS.roadStatus(this.devCode).then((data)=>{
                        if(data){
                            this.roadStatus.airTemperature=data.airTemperature;
                            this.roadStatus.airHumidness=data.airHumidness;
                            this.roadStatus.atmosphericPressure=data.atmosphericPressure;
                            this.roadStatus.photometricQuantity=data.photometricQuantity;
                            this.roadStatus.visibility=data.visibility;
                            this.roadStatus.pm10=data.pm10;
                            this.roadStatus.pm2p5=data.pm2p5;
                            this.roadStatus.waterFreezesThickness=data.waterFreezesThickness;
                            this.roadStatus.waterFreezesType=data.waterFreezesType;
                            this.roadStatus.roadTemperature=data.roadTemperature;
                            this.roadStatus.uploadTime=data.uploadTime;
                        }else{
                            this.dev.monitorRoadStatus = '0';
                        }
                    })
                }
            })
        },
        // 路面设备数据
        getdevWaterFreezeDevsta(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorWaterFreezeDevsta=='1'){
                    this.$api.ITGS.waterFreezeDevsta(this.devCode).then((data)=>{
                        if(data){
                            this.roadDev.devCode = data.devCode;
                            this.roadDev.rssi = data.rssi;
                            this.roadDev.runTime = data.runTime;
                            this.roadDev.swVer = data.swVer;
                            // this.roadDev.workTemp = data.workTemp;
                            this.roadDev.devTime = data.devTime;
                        }else{
                            this.dev.monitorWaterFreezeDevsta='0';
                        }
                    })
                }
            })
        },
        // 设备状态A
        getdevDevsta(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorDevsta=='1'){
                    this.$api.ITGS.devsta(this.devCode).then((data)=>{
                        if(data){
                            this.statusA.devCode = data.devCode;
                            this.statusA.devPowerType = data.devPowerType;
                            this.statusA.devChargeState = data.devChargeState;
                            this.statusA.rssi = data.rssi;
                            this.statusA.runTime = data.runTime;
                            this.statusA.devTime = data.devTime;
                        }else{
                            this.dev.monitorDevsta='0';
                        }
                    })
                }
            })
        },
        // 设备状态B
        getdevAppBeat(){
            return new Promise((reslove,reject)=>{
                if(this.dev.monitorAppBeat=='1'){
                    this.$api.ITGS.appBeat(this.devCode).then((data)=>{
                        if(data){
                            this.statusB.devCode = data.devCode;
                            this.statusB.netState = data.netState;
                            this.statusB.bootTime = data.bootTime;
                            this.statusB.runTime = data.runTime;
                            this.statusB.uploadTime = data.uploadTime;
                        }else{
                            this.dev.monitorAppBeat='0'
                        }
                    })
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.van-loading{margin: 45% auto;}
.van-cell__title, .van-cell__value{
    -webkit-box-flex: inherit;
    -webkit-flex: inherit;
    flex: inherit;; 
    }
.block{
    padding: 2%;
    border-radius: 10px;
    background-color: #fff;
    .data{
        color:#656565;
        font-weight: bold;
        text-align: left;
        padding-left:5% ;
        font-size: .95rem;
        span{font-weight: 300;}
        .van-row{padding-bottom: 4%;}
    }
    .van-cell{
        font-size: .95rem;
        font-weight: bold;
        padding: 4% 5%;
        color: #656565;
        display: flex;
        justify-content: space-between;
    }
    .van-cell__value{color: #0957ff;font-weight: normal;}
}
.update-time{font-size: .7rem;font-weight:normal;color: #676767cc;}
.padding-time{padding: 4% 5%;}
.table{
    text-align: center;
    .table-head{
        font-weight: bold;border-bottom: 1px solid #f0f0f0;font-size: .85rem;
        div{border: 2px solid #f0f0f0;padding: 8px 2px;}
    }
    .table-body{
        font-size: .85rem;
        div{border: 1px solid #f0f0f0;padding: 8px 2px;}
    }
}
.van-empty{padding: 35% 0;}
</style>